import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import JobOfferForm from '../../Modules/ContactForms/JobForm'

function SalesManagerOne() {
    return(
        <Container>
        <Row>
          <Col>
            <h3>Менеджер по закупкам</h3>
            <p>г.Клин</p>
          </Col>
          <Col>
            <h5>до 60 000 руб. на руки</h5>
          </Col>
        </Row>
        <Row>
            <Col>
                <p> 
                    Требуемый опыт работы: 1–3 года<br/>
                    Полная занятость, полный день<br/>
                    Возможно временное оформление: договор услуг, подряда, ГПХ, самозанятые, ИП
                </p>
            </Col>
        </Row>
        <Row>
          <Col>
          <p><strong>Обязанности:</strong></p>
            <ul>
                <li>обработка спецификаций на закупку материалов и комплектующих для сборки холодильных установок, поступающих от проектировщиков. Загрузка спецификаций в 1С УНФ</li>
                <li>размещение заказов на материалы и комплектующие по спецификациям у поставщиков, в том числе в 1С. Операции оприходования / списания</li>
                <li>организация доставки комплектующих и материалов на производство (собственным транспортом компании и наемным)</li>
                <li>контроль поступления товаров и взаиморасчетов с поставщиками</li>
                <li>участие в инвентаризациях</li>
            </ul>
          </Col>
        </Row>
        <Row>
        <Col>
          <p><strong>Требования:</strong></p>
            <ul>
                <li>опыт работы менеджером по закупкам от года с использованием 1С. Полное понимание закупочных процессов.</li>
                <li>знание пакета 1С 8.3: УПП, УНФ, Бухгалтерия. Любого из приложений на выбор, главное владение общим функционалом (минимум закупок-продаж, в идеале – производство)</li>
                <li>уверенное владение пакетом офисных приложений (Word, Excel, Outlook и т.п.)</li>
                <li>отличное знание MS Excel. Обязательно сводные таблицы. Макросы и VBA – преимущество.</li>
                <li>высокая скорость операционной работы</li>
                <li>готовность к выполнению тестовых заданий</li>
            </ul>
          </Col>
        </Row>
        <Row>
        <Col>
          <p><strong>Условия:</strong></p>
            <ul>
                <li>рабочая неделя Пн-Пт 9.00-18.00 (возможно изменение временных рамок по согласованию)</li>
                <li>своевременная выплата ЗП.</li>
                <li>предусмотрен переходный период – 1 мес. исп. срок работа в офисе с руководителем по закупкам (г. Химки, здание Bosch), затем постоянная работа на производстве в г. Клин</li>
                <li>оклад на исп. срок 1мес. - 45 тыс. руб., далее до 60 тыс. руб. по результатам работы</li>
            </ul>
          </Col>
        </Row>
        <Row>
        <Col>
          <h4><strong>Ключевые навыки:</strong></h4>
            <ul>
                <li>Закупка товаров и услуг</li>
                <li>Работа с поставщиками</li>
                <li>Закупки</li>
                <li>1С: Предприятие 8</li>
                <li>MS Outlook</li>
                <li>MS Excel</li>
                <li>MS Office</li>
            </ul>
          </Col>
        </Row>
        <Row>
            <Col>
                <JobOfferForm />
            </Col>
        </Row>
        <Row>
            <Col><p></p></Col>
        </Row>
        </Container>
    )
}

export default SalesManagerOne;