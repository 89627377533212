import * as React from "react"
import {Container, Row, Col} from 'react-bootstrap'
import Seo from "../components/seo"
import TopDarkRelative from "../components/Modules/Top/TopDarkRelative";
import Footer from "../components/Modules/FooterPage"
import SalesManagerOne from "../components/OtherPages/JobOffers/SalesManagerOne";


const OfferPage = () => (
  <>
    <Seo 
      title="Вакансии компании Рефинжиниринг" 
      description="Работа в холодильной компании для специалиста по закупкам"
    />

    <Container fluid>
        <Row>
            <Col>
            <TopDarkRelative />
            </Col>
        </Row>
        <Container >
        <Row>
          <Col>
            <h1>Вакансии компании "Рефинжиниринг"</h1>
          </Col>
        </Row>
        <SalesManagerOne />
    </Container>
        <Row>
            <Col style={{padding: '0'}}>
                <Footer />
            </Col>
        </Row>
    </Container>

  </>
)

export default OfferPage
