import React from 'react'
import {Button} from "react-bootstrap"
import ModalWindow from './ModalWindow'


export default function OrderButton() {
    const [modalShow, setModalShow] = React.useState(false);
  
    return (
      <>
        <Button variant="ref" size="xl" type="submit" className="ref-blue-button" onClick={() => setModalShow(true)}>
            ОТПРАВИТЬ РЕЗЮМЕ
        </Button>
  
        <ModalWindow
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </>
    );
  }